import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Box, Text, Flex } from "@chakra-ui/react";
import Select from "react-select";

import CTAButton from "../../components/CTAButton";
import LinkWrapper from "../../components/LinkWrapper";
import OneColumnText from "../BlogPostSections/OneColumnText";

/* IMPORT APP CONTEXT */
import { AppContext } from "../../context";

function ProductOverview(props) {
  const {
    productData,
    stateSelected = 0,
    setStateSelected,
    defaultState,
    setDefaultState,
    shopAllRoute,
  } = props;
  const {
    category,
    subcategory,
    name,
    infoPerState,
    lineages,
    durationImage,
    secondBadge,
  } = productData;
  const appContext = React.useContext(AppContext);
  const filters = appContext.queryFilters;

  /* GET FIND ROUTE FROM FOOTER QUERY */
  const data = useStaticQuery(graphql`
    query {
      allSanityFooter {
        nodes {
          routeToFindPage {
            route
          }
        }
      }
    }
  `);

  const findRoute = data.allSanityFooter.nodes[0].routeToFindPage.route;

  /* STATES AVAILABLE */
  const [stateOptions, setStateOptions] = React.useState([]);
  const [defaultStateOption, setDefaultStateOption] = React.useState(0);

  let allLineages = "";
  lineages.map((item) => (allLineages += `${item.name} | `));

  React.useEffect(() => {
    const allStateOptions = infoPerState.map((item, indx) => {
      return {
        value: item.state.slug.current,
        label: item.state.abbrevation.toUpperCase(),
        index: indx,
      };
    });

    let temporalDefault = filters.state
      ? allStateOptions?.findIndex(
          (item) => item?.value === filters?.state[filters?.state?.length - 1]
        )
      : 0;
    if (temporalDefault < 0) temporalDefault = 0;

    setDefaultStateOption(temporalDefault);
    setStateSelected(temporalDefault);

    setStateOptions(allStateOptions);
  }, []);

  /* STYLES */
  const oneColumnTextStyles = {
    fontColor: "primary",
    fontSizes: {
      small: { base: "14px", md: "14px" },
      normal: { base: "15px", md: "18px" },
      h4: { base: "17px", md: "20px" },
      h3: { base: "19px", md: "22px" },
      h2: { base: "21px", md: "24px" },
      h1: { base: "23px", md: "26px" },
    },
    fontWeights: {
      normal: "400",
      strong: "600",
    },
    lineHeights: {
      small: { base: "12px", md: "12px" },
      normal: { base: "25px", md: "30px" },
      h4: { base: "27px", md: "32px" },
      h3: { base: "29px", md: "34px" },
      h2: { base: "31px", md: "36px" },
      h1: { base: "33px", md: "38px" },
    },
    letterSpacings: {
      small: { base: "0px", md: "0px" },
      normal: { base: "0px", md: "0px" },
      h4: { base: "0px", md: "0px" },
      h3: { base: "0px", md: "0px" },
      h2: { base: "0px", md: "0px" },
      h1: { base: "0px", md: "opx" },
    },
    textAlign: { base: "left", md: "left" },
    mbSection: { base: "0px", md: "0px" },
    mbContainers: { base: "25px", md: "25px" },
    hover: { borderColor: "primary" },
    linkFontStyle: "italic",
    listsLeftMargin: { base: "20px", md: "20px" },
  };

  /* UPDATE DETAILS AND COMPOUND INFO WHEN STATE CHANGES */
  const [detailsToShow, setDetailsToShow] = React.useState(null);
  const [compoundInfoToShow, setCompoundInfoToShow] = React.useState(null);

  React.useEffect(() => {
    if (infoPerState[stateSelected]?.useDefaultDetails) {
      defaultState?._rawDetails
        ? setDetailsToShow(defaultState._rawDetails)
        : null;
    } else {
      infoPerState[stateSelected]?._rawDetails
        ? setDetailsToShow(infoPerState[stateSelected]._rawDetails)
        : null;
    }

    if (infoPerState[stateSelected]?.useDefaultCompoundInfo) {
      defaultState?.compoundInformation
        ? setCompoundInfoToShow(defaultState.compoundInformation)
        : null;
    } else {
      infoPerState[stateSelected]?.compoundInformation
        ? setCompoundInfoToShow(infoPerState[stateSelected].compoundInformation)
        : null;
    }
  }, [stateSelected]);

  return (
    <>
      <Box
        w="100%"
        h="auto"
        bgColor="grayBG"
        display="flex"
        justifyContent="center"
        alignItems="flex-end"
        position="relative"
      >
        {/* PRODUCT CONTENT */}
        <Flex
          w={{ base: "90%", ms: "84%", mds: "84%", lg: "84%", xl: "1200px" }}
          h="auto"
          direction="column"
          pb="5px"
          style={{ zIndex: 2 }}
        >
          {/* TITLE */}
          <Flex
            w="100%"
            direction="row"
            justifyContent={{ base: "center", md: "flex-start" }}
            alignItems={{ base: "center", md: "center" }}
            py={{ base: "33px" }}
            ml={{ md: "-40px" }}
            mt={{ base: "35px", md: "15px", lg: "15px" }}
            display={{ base: "none", md: "flex" }}
          >
            <TitleLinkItem text={"shop all"} link={shopAllRoute} />

            <TitleTextItem text={"\u00A0" + "|" + "\u00A0"} hover={null} />

            <TitleLinkItem
              text={`${category.name}`}
              link={category.ctaRoute.route}
            />

            <TitleTextItem text={"\u00A0" + "|" + "\u00A0"} hover={null} />

            {subcategory && (
              <Flex>
                <TitleLinkItem
                  text={`wana ${subcategory.name}`}
                  link={subcategory.ctaRoute.route}
                />
                <TitleTextItem text={"\u00A0" + "|" + "\u00A0"} hover={null} />
              </Flex>
            )}

            <TitleTextItem text={name} hover={null} />
          </Flex>

          {/* PRODUCT */}
          <Flex
            w="100%"
            h="auto"
            direction={{ base: "column", lgs: "row" }}
            alignItems={{ base: "center", md: "flex-start" }}
            mt={{ base: "40px", md: "0" }}
          >
            {/* IMAGE CONTAINER */}
            <Flex
              w="100%"
              h="100%"
              direction={{ base: "column" }}
              alignItems="center"
              // mt={{base:'40px', md:'26px'}}
              mr={{ base: "0", lgs: "3.2vw", xl: "47px" }}
              position="relative"
            >
              {/* DURATION - ONSET IMAGE DESKTOP */}
              {(durationImage || secondBadge) && (
                <Flex
                  display={{ base: "none", md: "block" }}
                  position="absolute"
                  top={{ md: "8vw", lg: "8vw", lgs: "8vw", xl: "115px" }}
                  left={{ md: "0", lg: "0", lgs: "-42px" }}
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  {durationImage && (
                    <Box maxW={{ md: "172px" }} maxH={{ md: "111px" }}>
                      <GatsbyImage
                        image={durationImage.asset.gatsbyImageData}
                        alt={"wana duration/onset image"}
                      />
                    </Box>
                  )}

                  {secondBadge && (
                    <Box
                      maxW={{ md: "172px" }}
                      maxH={{ md: "111px" }}
                      mt={{ md: durationImage ? "25px" : "0" }}
                    >
                      <GatsbyImage
                        image={secondBadge.asset.gatsbyImageData}
                        alt={"wana second badge image"}
                      />
                    </Box>
                  )}
                </Flex>
              )}

              {/* DURATION - ONSET IMAGE MOBILE */}
              {(durationImage || secondBadge) && (
                <Flex
                  display={{ base: "flex", md: "none" }}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  mx="auto"
                  my={{ base: "10px" }}
                >
                  {durationImage && (
                    <Box
                      maxW={{ base: "127px", md: "172px" }}
                      maxH={{ base: "82px", md: "111px" }}
                    >
                      <GatsbyImage
                        image={durationImage.asset.gatsbyImageData}
                        alt={"wana duration/onset image"}
                      />
                    </Box>
                  )}

                  {secondBadge && (
                    <Box
                      maxW={{ base: "127px", md: "172px" }}
                      maxH={{ base: "82px", md: "111px" }}
                      ml={{ base: durationImage ? "25px" : "0" }}
                    >
                      <GatsbyImage
                        image={secondBadge.asset.gatsbyImageData}
                        alt={"wana second badge image"}
                      />
                    </Box>
                  )}
                </Flex>
              )}

              {/* PRODUCT IMAGE */}
              <Flex
                w={{
                  base: "315px",
                  md: "46.42vw",
                  mds: "46.43vw",
                  lg: "46.43vw",
                  lgs: "46.42vw",
                  xl: "668px",
                }}
                // h={{base:'252px', md:'535px', lgs:'510px', xl:'535px'}}
                position="relative"
                justifyContent="center"
                alignItems="flex-end"
                className="product-overview-image fadeIn"
                // border="1px"
              >
                {infoPerState.length > 0 && (
                  <GatsbyImage
                    image={
                      infoPerState[stateSelected]?.productImage?.asset
                        ?.gatsbyImageData
                    }
                    alt={"wana product image"}
                    // imgStyle={{objectFit:'cover', maxWidth:'668px', height:'535px', margin:'auto'}}
                  />
                )}
              </Flex>

              <Flex
                justifyContent="center"
                alignItems="center"
                mt={{ base: "10px", md: "35px" }}
              >
                <Text
                  color="primary"
                  fontSize={{ base: "10px", md: "12px" }}
                  fontStyle="italic"
                  fontWeight="600"
                  letterSpacing="0"
                  lineHeight={{ base: "30px" }}
                  textAlign="center"
                  pb={{ base: "0", md: "7px" }}
                >
                  {/* <span style={{ textTransform: "capitalize" }}>{`${
                    infoPerState?.length > 0
                      ? infoPerState[stateSelected]?.state.name
                      : "state"
                  } `}</span> */}
                  {`National packaging pictured. Due to local regulations, label may look different in your state.`}
                </Text>
              </Flex>
            </Flex>

            {/* PRODUCT INFO CONTAINER */}
            <Flex
              w={{ base: "100%" }}
              h="auto"
              direction="column"
              mx={{ base: "auto", md: "0" }}
              px={{ base: "5.28vw", lgs: "0" }}
              pt={{ base: "0", md: "15px" }}
              mt={{ base: "35px", md: "0" }}
            >
              {/* PRODUCT CATEGORY SUBCATEGORY */}
              <Text
                color="primary"
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight="800"
                letterSpacing={{ base: "4px", md: "4.8px" }}
                lineHeight={{ base: "20px", md: "20px" }}
                textTransform="uppercase"
                textAlign={{ base: "center", md: "left" }}
                pb={{ base: "6px", md: "12px" }}
              >
                {`${subcategory ? `wana ${subcategory.name}` : ""} ${
                  category.name
                }`}
              </Text>

              {/* PRODUCT NAME */}
              <Text
                color="primary"
                fontSize={{ base: "36px", md: "60px" }}
                fontWeight="900"
                letterSpacing={{ base: "-0.56px", md: "-0.11px" }}
                lineHeight={{ base: "30px", md: "58px" }}
                textTransform="uppercase"
                textAlign={{ base: "center", md: "left" }}
                pb={{ base: "6px", md: "0" }}
              >
                {name}
              </Text>

              {/* LINEAGE */}
              <Text
                color="primary"
                fontSize={{ base: "22px", md: "30px" }}
                fontWeight="900"
                letterSpacing={{ base: "0" }}
                lineHeight={{ base: "1", md: "50px" }}
                textTransform="uppercase"
                textAlign={{ base: "center", md: "left" }}
                mb={{ base: "28px", md: "25px" }}
              >
                {allLineages !== ""
                  ? `// ${allLineages.substring(0, allLineages.length - 2)}`
                  : " "}
              </Text>

              {/* DETAILS */}
              <Box
                maxW={{
                  base: "76.95vw",
                  md: "100%",
                  lg: "73.17vw",
                  lgs: "32.53vw",
                  xl: "468px",
                }}
                mx={{ base: "auto", md: "0" }}
                // mb={{base:'23px', md:'23px'}}
                // border="1px"
              >
                {detailsToShow && (
                  <OneColumnText
                    _rawOneColumnText={detailsToShow}
                    styles={oneColumnTextStyles}
                  />
                )}
              </Box>

              {/* WARNING */}
              {infoPerState[stateSelected] &&
                infoPerState[stateSelected]?.warning && (
                  <Box
                    display="flex"
                    justifyContent={{ base: "center", md: "flex-start" }}
                    alignItems="center"
                    mx={{ base: "auto", md: "0" }}
                    mb={{ base: "20px", md: "20px" }}
                  >
                    <Text
                      maxW={{
                        base: "76.95vw",
                        md: "100%",
                        lg: "73.17vw",
                        lgs: "32.53vw",
                        xl: "468px",
                      }}
                      color="primary"
                      fontSize={{ base: "17px" }}
                      fontWeight="900"
                      letterSpacing="0.5px"
                      lineHeight={{ base: "20px", md: "18px" }}
                      textTransform="uppercase"
                      textAlign={{ base: "center", md: "left" }}
                    >
                      {`warning: ${infoPerState[stateSelected]?.warning}`}
                    </Text>
                  </Box>
                )}

              {/* AVAILABLE */}
              <Box
                display="flex"
                justifyContent={{ base: "center", md: "flex-start" }}
                alignItems="center"
                mb={{ base: "33px", md: "30px" }}
              >
                {/* TEXT */}
                <Box mr="15px">
                  <Text
                    color="primary"
                    fontSize={{ base: "14px" }}
                    fontWeight="900"
                    letterSpacing="0.5px"
                    lineHeight={{ base: "18px" }}
                    textTransform="uppercase"
                  >
                    available in
                  </Text>
                </Box>

                {/* DROPDOWN */}
                <Box
                  w={{ base: "94px" }}
                  h={{ base: "40px" }}
                  border="2px"
                  borderColor="#E3E1ED"
                  // px="7px"
                >
                  {stateOptions.length > 0 && (
                    <Select
                      className="state-dropdown-container"
                      classNamePrefix="state-dropdown"
                      isSearchable={false}
                      defaultValue={stateOptions[defaultStateOption]}
                      options={stateOptions}
                      onChange={(state) => setStateSelected(state.index)}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        outline: "none",
                        outlineWidth: "transparent",
                        outlineStyle: "none",
                        colors: {
                          ...theme.colors,
                          neutral0: "#2F2865",
                          neutral90: "white",
                          neutral70: "white",
                          neutral80: "white",
                          neutral50: "white",
                        },
                      })}
                    />
                  )}
                </Box>
              </Box>

              {/* COMPOUND */}
              {compoundInfoToShow && (
                <CompoundDetails compoundInfoToShow={compoundInfoToShow} />
              )}

              {/* BUTTONS */}
              <Flex>
                {/* FIND PRODUCT */}
                <Box
                  width={{ base: "152px", md: "152px" }}
                  mx={{ base: "auto", md: "0" }}
                  mr={{ base: "28px", md: "28px" }}
                  mt={{ base: "13px", md: "13px" }}
                >
                  <CTAButton
                    href={findRoute}
                    width={"100%"}
                    height={{ base: "48px", lg: "48px" }}
                    fontSize={{ base: "13px", md: "13px" }}
                    fontWeight="900"
                    bgColor={"transparent"}
                    color={"primary"}
                    border={"2px"}
                    borderColor={"primary"}
                    _hover={{ bg: "#272158", color: "white" }}
                    ctaText={"find product"}
                  />
                </Box>

                {/* BUY NOW */}
                <Box
                  width={{ base: "152px", md: "152px" }}
                  mx={{ base: "auto", md: "0" }}
                  mt={{ base: "13px", md: "13px" }}
                >
                  {/* <CTAButton
                    onClick={appContext.actions.buyNowModalControl}
                    width={"100%"}
                    height={{ base: "48px", lg: "48px" }}
                    fontSize={{ base: "13px", md: "13px" }}
                    fontWeight="900"
                    bgColor={"greenMantis"}
                    color={"white"}
                    _hover={{ bg: "#311D56", borderColor: "#311D56" }}
                    ctaText={"order online"}
                  /> */}
                </Box>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Box>

      {/* BOTTOM SVG */}
      <Box
        w="100%"
        h={{ base: "11.46vw", xl: "165px" }}
        style={{ zIndex: 0 }}
        // display="none"
      >
        <svg
          viewBox="0 0 500 149"
          preserveAspectRatio="none"
          style={{ width: "100%", height: "100%", zIndex: 0 }}
        >
          <path
            fill="#F1F0FA"
            fillOpacity="1"
            // d="M-0.84,142.61 C216.98,113.98 385.15,180.11 501.97,123.86 L500.00,0.00 L0.00,0.00 Z"
            d="M-83.23,143.59 C273.42,-32.05 261.57,257.08 502.54,93.27 L500.00,0.00 L0.00,0.00 Z"
          ></path>
        </svg>
      </Box>
    </>
  );
}

const CompoundDetails = ({ compoundInfoToShow }) => {
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <Box
      maxW={{ base: "466px", md: "max-content" }}
      mx={{ base: "auto", md: "0" }}
      mb="40px"
      bgColor="#E5E4F2"
    >
      {/* TABS */}
      <Flex
        py="10px"
        borderBottom="2px"
        borderColor="white"
        justifyContent={{ base: "flex-start" }}
        alignItems="center"
      >
        {/* PER PIECE */}
        <Text
          bgColor={activeTab === 0 ? "#312669" : "transparent"}
          color={activeTab === 0 ? "white" : "#A9A7C2"}
          fontFamily="Outfit"
          fontSize="12px"
          fontWeight="bold"
          lineHeight="21px"
          letterSpacing="1.6px"
          textAlign="center"
          px="10px"
          borderRadius="12px"
          role="button"
          ml="12px"
          mr="6px"
          onClick={() => setActiveTab(0)}
        >
          PER PACKAGE
        </Text>

        {/* PER PACKAGE */}
        <Text
          bgColor={activeTab === 1 ? "#312669" : "transparent"}
          color={activeTab === 1 ? "white" : "#A9A7C2"}
          fontFamily="Outfit"
          fontSize="12px"
          fontWeight="bold"
          lineHeight="21px"
          letterSpacing="1.6px"
          textAlign="center"
          px="10px"
          borderRadius="12px"
          role="button"
          ml="6px"
          mr="12px"
          onClick={() => setActiveTab(1)}
        >
          PER PIECE
        </Text>
      </Flex>

      {/* COMPOUNDs PER PACKAGE */}
      <Flex
        display={activeTab === 0 ? "flex" : "none"}
        justifyContent="center"
        flexWrap={{ base: "wrap", msx: "wrap" }}
      >
        {compoundInfoToShow.map((compound, indx) => {
          return (
            <Flex
              key={indx}
              h="64px"
              mt="6px"
              direction="column"
              justifyContent="center"
              alignItems="center"
              color="primaryDarker"
              fontFamily="Outfit"
              fontSize={{ base: "21px", md: "21px" }}
              fontWeight="bold"
              lineHeight={{ base: "1", md: "1" }}
              letterSpacing={{ base: "1px", md: "1px" }}
              textAlign="center"
              textTransform="uppercase"
              mx="10px"
              className="fadeIn"
            >
              <Text fontSize={{ base: "15px", md: "15px" }} textAlign="center">
                {`${compound.compoundPerPKG}MG`}
              </Text>

              <Text textAlign="center">{compound.compound.name}</Text>
            </Flex>
          );
        })}
      </Flex>

      {/* COMPOUNDs PER PIECE */}
      <Flex
        display={activeTab === 1 ? "flex" : "none"}
        justifyContent="center"
        flexWrap={{ base: "wrap", msx: "wrap" }}
      >
        {compoundInfoToShow.map((compound, indx) => {
          return (
            <Flex
              key={indx}
              h="64px"
              mt="6px"
              direction="column"
              justifyContent="center"
              alignItems="center"
              color="primaryDarker"
              fontFamily="Outfit"
              fontSize={{ base: "21px", md: "21px" }}
              fontWeight="bold"
              lineHeight={{ base: "1", md: "1" }}
              letterSpacing={{ base: "1px", md: "1px" }}
              textAlign="center"
              textTransform="uppercase"
              mx="10px"
              className="fadeIn"
            >
              <Text fontSize={{ base: "15px", md: "15px" }} textAlign="center">
                {`${compound.compoundPerPiece}MG`}
              </Text>

              <Text textAlign="center">{compound.compound.name}</Text>
            </Flex>
          );
        })}
      </Flex>
    </Box>
  );
};

const TitleLinkItem = ({ text, link }) => {
  return (
    <LinkWrapper href={link}>
      <TitleTextItem text={text} hover />
    </LinkWrapper>
  );
};

const TitleTextItem = ({ text, hover }) => {
  return (
    <Text
      color="primary"
      fontSize={{ base: "11px", md: "13px", lg: "14px" }}
      lineHeight="1"
      letterSpacing="0.5px"
      textTransform="capitalize"
      borderBottom="1px"
      borderColor="transparent"
      _hover={hover ? { borderBottom: "1px", borderColor: "#272158" } : null}
    >
      {text}
    </Text>
  );
};

export default ProductOverview;
